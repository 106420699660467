import { caseInsensitiveComparator } from "@asmbl/shared/sort";
import { Property } from "@flatfile/api/api";
import { FlatfileRecord } from "@flatfile/hooks";
import { FFValidator, recordHasValue } from "src/utils";
import { INTEGER_VALIDATOR } from "./Validators";

export function getPromotionFields(
  positions: { id: number; name: string }[]
): Property[] {
  const sortedPositions = Array.from(positions)
    .sort((a, b) => caseInsensitiveComparator(a.name, b.name))
    .map(({ name, id }) => ({
      label: name,
      value: id.toString(),
    }));
  return [
    {
      label: "New Assemble Position Name",
      key: "salaryPromotionId",
      type: "enum",
      config: {
        options: [{ label: "", value: "" }, ...sortedPositions],
      },
    },
    {
      label: "Promotion Adjustment ($)",
      key: "salaryPromotion",
      type: "number",
    },
    {
      label: "Promotion Adjustment (%)",
      key: "salaryPromotionPercent",
      type: "number",
    },
    {
      label: "New Internal Job Title",
      key: "salaryPromotionTitle",
      type: "string",
      description:
        "This is separate from the Assemble Position Name. For example, if the new Assemble position is 'Analyst - IC 2', this field might say 'Senior Analyst'",
    },
    {
      label: "Promotion Notes",
      key: "salaryPromotionNotes",
      type: "string",
    },
  ];
}

export const getPromotionValidators = () => [
  FFValidator.requiredWith("salaryPromotionId", [
    "salaryPromotion",
    "salaryPromotionPercent",
    "salaryPromotionNotes",
    "salaryPromotionTitle",
  ]),
  FFValidator.regexMatches(
    "salaryPromotion",
    RegExp(INTEGER_VALIDATOR),
    "Must be a whole number"
  ),
];

export function validatePromotion(
  record: FlatfileRecord,
  positions: { id: number; name: string }[]
): void {
  const promoId = record.get("salaryPromotionId");
  if (recordHasValue(promoId)) {
    if (positions.every((p) => p.id !== promoId)) {
      record.addError(
        "salaryPromotionId",
        "Position ID not found in the list of positions."
      );
    }
  }
}
